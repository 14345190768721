import { createClient } from '@supabase/supabase-js';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;
/*

For netlify

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;

On netlify add eniornmental vairables 

Add your environment variables like this:

Key: REACT_APP_SUPABASE_URL

Value: <your-supabase-url>

Key: REACT_APP_SUPABASE_ANON_KEY

Value: <your-supabase-anon-key>



*/




export const supabase = createClient(supabaseUrl, supabaseAnonKey);

// Function to check if a user exists based on their wallet address
export const checkDatabaseForAddress = async (address) => {
  try {

    // Query the `users` table to check if the wallet address exists
    const { data, error } = await supabase
      .from('users')
      .select('*')
      .eq('wallet_address', address)
      .maybeSingle();  // Use maybeSingle() to avoid error when no rows are returned

    if (error) {
      console.error('Error fetching user data:', error);
      return null;
    }


    return data ? data : null;  // Return the user data if found, otherwise null
  } catch (error) {
    console.error('Error connecting to Supabase:', error);
    return null;
  }
};

// Function to check if a username exists in the database
export const doesUsernameExist = async (username) => {
  try {
    const { data, error } = await supabase
      .from('users')
      .select('username')
      .eq('username', username)
      .single();

    if (error) {
      console.error('Error checking username:', error);
      return false; // Username does not exist
    }

    return data ? true : false; // Return true if username exists
  } catch (error) {
    console.error('Error querying Supabase for username:', error);
    return false;
  }
};


// Function to save a new user to the database
export const saveUserToDatabase = async (walletAddress, username) => {
  try {
    const { data, error } = await supabase
      .from('users')
      .insert([
        {
          wallet_address: walletAddress,
          username: username,
          total_xp: 0, // Set XP to 0 initially
        }
      ]);

    if (error) {
      console.error('Error saving user:', error);
      return { success: false, error };
    }

    return { success: true, data };
  } catch (error) {
    console.error('Error inserting user data:', error);
    return { success: false, error };
  }
};



//saving xp

export const saveXPToDatabase = async (walletAddress, score) => {
  try {
  

    // Fetch the user's current XP
    const { data: userData, error: fetchError } = await supabase
      .from('users')
      .select('total_xp')
      .eq('wallet_address', walletAddress)
      .maybeSingle();

    if (fetchError) {
      console.error('Error fetching user XP:', fetchError);
      return;
    }

    if (!userData) {
     
      return;
    }

    // Calculate the new XP by adding the score to the current total XP
    const newTotalXP = userData.total_xp + score;

    // Update the user's total XP in the database
    const { error: updateError } = await supabase
      .from('users')
      .update({ total_xp: newTotalXP })
      .eq('wallet_address', walletAddress);

    if (updateError) {
      console.error('Error updating XP:', updateError);
    } else {
     

      // Fetch the updated XP to verify it's saved in the database
      const { data: updatedUserData, error: updatedFetchError } = await supabase
        .from('users')
        .select('total_xp')
        .eq('wallet_address', walletAddress)
        .maybeSingle();

      if (updatedFetchError) {
        console.error('Error fetching updated user XP:', updatedFetchError);
        return;
      }

      
    }
  } catch (error) {
    console.error('Error saving XP:', error);
  }
};


// Function to fetch and update user's XP
export const fetchUserXP = async (walletAddress) => {
  try {
   
    const { data, error } = await supabase
      .from('users')
      .select('total_xp')
      .eq('wallet_address', walletAddress)
      .maybeSingle();  // Fetches the XP for the connected wallet address

    if (error) {
      console.error('Error fetching XP:', error);
      return null;
    }

    if (data) {
     
      return data.total_xp;  // Return the fetched XP
    }

    return null;  // Return null if no data is found
  } catch (error) {
    console.error('Error fetching XP:', error);
    return null;
  }
};


// Function to fetch the top 25 users by XP
export const fetchTopUsers = async () => {
  try {
    const { data, error } = await supabase
      .from('users')
      .select('username, total_xp, wallet_address')
      .order('total_xp', { ascending: false })
      .limit(25);

    if (error) {
      console.error('Error fetching top users:', error);
      return null;  // Return null if there's an error
    }

    return data;  // Return the fetched data
  } catch (error) {
    console.error('Error fetching top users:', error);
    return null;
  }
};

// Fetch all progress for the selected category
export const fetchCategoryProgress = async (walletAddress, category) => {
  try {
    const { data, error } = await supabase
      .from('progress')
      .select('highest_level_completed')
      .eq('wallet_address', walletAddress) // Match wallet_address
      .eq('category', category)            // Match category
      .maybeSingle(); // Fetch only one entry if it exists

    // Handle error in fetching progress
    if (error) {
      console.error('Error fetching category progress:', error);
      return { success: false, error };
    }

    // If no progress exists, return default level 1
    if (!data) {
      return { success: true }; 
    }

    return { success: true, highest_level_completed: data.highest_level_completed }; // Return progress
  } catch (error) {
    console.error('Error fetching category progress:', error);
    return { success: false, error };
  }
};





// Mark a level as completed by updating the highest level completed for a specific category
export const completeLevel = async (walletAddress, category, level) => {
  try {
    // First, check if there's already progress for the specific category
    const { data, error: fetchError } = await supabase
      .from('progress')
      .select('highest_level_completed')
      .eq('wallet_address', walletAddress) // Match wallet_address
      .eq('category', category)            // Match category
      .maybeSingle();

    if (fetchError) {
      console.error('Error fetching user progress:', fetchError);
      return { success: false, error: fetchError };
    }

    // If no progress exists, insert a new row
    if (!data) {
      const { error: insertError } = await supabase
        .from('progress')
        .insert({
          wallet_address: walletAddress,
          category: category,
          highest_level_completed: level,
        });

      if (insertError) {
        console.error('Error inserting new progress:', insertError);
        return { success: false, error: insertError };
      }

      return { success: true }; // Successfully inserted new progress
    }

    // If progress exists, only update if the completed level is higher than the current progress
    if (data.highest_level_completed < level) {
      const { error: updateError } = await supabase
        .from('progress')
        .update({
          highest_level_completed: level,
        })
        .eq('wallet_address', walletAddress) // Ensure correct wallet_address
        .eq('category', category);           // Ensure correct category

      if (updateError) {
        console.error('Error updating highest level completed:', updateError);
        return { success: false, error: updateError };
      }
    }

    return { success: true }; // Successfully updated or no update needed
  } catch (error) {
    console.error('Error completing level:', error);
    return { success: false, error };
  }
};





//Daily QUestions 


export const convertToDatabaseDate = (date) => {
  if (!date) {
    console.error('Invalid date provided');
    return null;
  }
  // Make sure the date exists and has the correct format
  return new Date(date).toISOString().split('T')[0]; // Format to YYYY-MM-DD
};

// Fetch the daily question for the current date
export const fetchDailyQuestion = async () => {
  try {
    const today = new Date();
    const dateString = today.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }); // MM/DD/YYYY format
   

    const { data, error } = await supabase
      .from('daily_questions')
      .select('*')
      .eq('date', dateString) // Ensure you're querying by the right date format
      .maybeSingle();

    if (error) {
      console.error("Error fetching daily question:", error);
      return null;
    }

    if (!data) {
      console.error("No daily question data found for date:", dateString);
      return null;
    }

  
    return data;
  } catch (error) {
    console.error('Error fetching daily question:', error);
    return null;
  }
};




export const fetchDailyAttempts = async (walletAddress) => {
  const today = convertToDatabaseDate(new Date()); // Ensure date is valid

  try {
    const { data, error } = await supabase
      .from('daily_attempts')
      .select('*')
      .eq('wallet_address', walletAddress)
      .eq('date', today);

    if (error || !data) {
      console.error('Error fetching daily attempts:', error || 'No data found');
      return null;
    }

    return data;
  } catch (error) {
    console.error('Error fetching daily attempts:', error);
    return null;
  }
};



// Record the user's attempt
export const recordDailyAttempt = async (walletAddress, answeredCorrectly) => {
  try {
    const { data, error } = await supabase
      .from('daily_attempts')
      .insert({
        wallet_address: walletAddress,
        date: new Date().toISOString().split('T')[0],
        answered_correctly: answeredCorrectly,
      });

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error recording daily attempt:', error);
    return null;
  }
};
