import { useEffect, useState } from 'react';
import { fetchDailyQuestion, fetchDailyAttempts, recordDailyAttempt, saveXPToDatabase } from './supabaseClient';

const DailyQuestion = ({ walletAddress, updateXP }) => {
  const [dailyQuestion, setDailyQuestion] = useState(null);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [attempted, setAttempted] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [canAttempt, setCanAttempt] = useState(true);
  const [loading, setLoading] = useState(true);

  // Fetch daily question and attempt status
  useEffect(() => {
    async function fetchData() {
      setLoading(true); // Start loading when fetching data
      
      // Fetch the daily question
      const question = await fetchDailyQuestion();
      if (question) {
        setDailyQuestion(question);
      } else {
        console.error('No daily question found.');
        setFeedback('No daily question available.');
        setLoading(false);
        return;
      }

      // Check if the user has attempted today's question
      const attempt = await fetchDailyAttempts(walletAddress);
      if (attempt && attempt.length > 0) {
        setAttempted(true);
        setCanAttempt(false); // Already attempted today
      } else {
        setAttempted(false); // No attempt found
        setCanAttempt(true);  // User can attempt
      }

      setLoading(false); // Done loading
    }

    if (walletAddress) {
      fetchData();
    } else {
      setLoading(false); // No wallet connected, stop loading
    }
  }, [walletAddress]);

  const handleAnswerSelection = (answer) => {
    setSelectedAnswer(answer);
    setFeedback(''); // Clear any previous feedback
  };

  const handleSubmit = async () => {
    if (!selectedAnswer) {
      setFeedback('Please select an answer.');
      return;
    }

    // Ensure the user hasn't already attempted
    const attempt = await fetchDailyAttempts(walletAddress);
    if (attempt && attempt.length > 0) {
      setFeedback('You have already attempted today. Try again tomorrow.');
      setCanAttempt(false);
      return;
    }

    // Check if the answer is correct
    const correct = selectedAnswer === dailyQuestion.correct_answer;
    if (correct) {
      setFeedback('Correct! You earned 10 XP. Come back tomorrow for a new question.');
      await saveXPToDatabase(walletAddress, 10); // Add 10 XP
      updateXP();           // Refresh XP on the UI
  
    } else {
      setFeedback('Incorrect. Come back tomorrow for another chance to earn 10 XP.');
    }

    // Record the attempt regardless of the answer
    await recordDailyAttempt(walletAddress, correct);

    // Disable further attempts for the day
    setAttempted(true);
    setCanAttempt(false);
  };

  if (loading) {
    return <p>Loading daily question...</p>;
  }

  if (!walletAddress) {
    return <p>Please connect your wallet to access the daily question.</p>;
  }

  return (
    <div className="daily-question-container">
  <h2>Daily Question</h2>
  <p className="instructions">Correctly Answer the Daily Question for 10XP! 1 Attempt Per Day 😮</p>
  <br/><br/>
  {attempted ? (
    <p className="feedback">{feedback || 'Daily question completed. Check back tomorrow for another question!'}</p>
  ) : (
    <>
      {dailyQuestion && (
        <>
          <p className="question">{dailyQuestion.question}</p>
          <div className="daily-answers">
            {[dailyQuestion.correct_answer, dailyQuestion.incorrect_answer1, dailyQuestion.incorrect_answer2, dailyQuestion.incorrect_answer3]
              .sort()
              .map((answer, idx) => (
                <button
                  key={idx}
                  className={`answer-btn ${selectedAnswer === answer ? 'selected' : ''}`}
                  onClick={() => handleAnswerSelection(answer)}
                  disabled={attempted}
                >
                  {answer}
                </button>
              ))}
          </div>
          {canAttempt && (
            <button className="submit-btn" onClick={handleSubmit}>
              Submit
            </button>
          )}
        </>
      )}
    </>
  )}
</div>

  );
};

export default DailyQuestion;
