import React, { useState, useEffect, useRef } from 'react';
import Confetti from 'react-confetti';
import './App.css';
import { supabase } from './supabaseClient'; // Import the Supabase client
import { checkDatabaseForAddress, saveUserToDatabase } from './supabaseClient'; //User tracking
import { saveXPToDatabase, fetchUserXP, fetchTopUsers } from './supabaseClient'; // progress tracking xp
import { fetchCategoryProgress, completeLevel } from './supabaseClient'; // progress tracking levels
import { Filter } from 'bad-words'; // Correct the import statement
import {useReward} from 'react-rewards';
import DailyQuestion from './DailyQuestions';



const App = () => {
  const [gameStarted, setGameStarted] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [score, setScore] = useState(0);
  const [incorrectScore, setIncorrectScore] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [feedback, setFeedback] = useState('');
  const [feedbackType, setFeedbackType] = useState('');
  const [showConfetti, setShowConfetti] = useState(false);
  const [showFinalScore, setShowFinalScore] = useState(false);
  const [timer, setTimer] = useState(30);
  const [shuffledAnswers, setShuffledAnswers] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [explanationText, setExplanationText] = useState('');
  const [timerRunning, setTimerRunning] = useState(true);
  const [showQuitPopup, setShowQuitPopup] = useState(false);
 const [selectedTopic, setSelectedTopic] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedLevel, setSelectedLevel] = useState(null);
  const [message, setMessage] = useState('Connect Your Cardano Lace Wallet To Sign In Or Create An Account!');

 

  const [walletConnected, setWalletConnected] = useState(false);
  const [walletAddress, setWalletAddress] = useState('');

  const [hasAccount, setHasAccount] = useState(false);  // To check if the user has an account
const [loggedIn, setLoggedIn] = useState(false);  // To check if the user is fully logged in


  const [username, setUsername] = useState('');  // Store the user's username
  const [totalXP, setTotalXP] = useState(0);  // State to hold the user's XP
 const [users, setUsers] = useState([]);  // State to store top 25 users

// State to hold the button classes
const [buttonClasses, setButtonClasses] = useState({});








  const connectLaceWallet = async () => {
    if (window.cardano && window.cardano.lace) {
      try {
        
        const wallet = await window.cardano.lace.enable();
        const usedAddresses = await wallet.getUsedAddresses();
  
        let address;
        if (usedAddresses.length > 0) {
          address = usedAddresses[0];
         
        } else {
          address = await wallet.getChangeAddress();
          
        }
  
        setWalletAddress(address);
       
     
  
        // Check if the wallet address exists in the database
        const userEntry = await checkDatabaseForAddress(address);
       
  
        if (userEntry) {
          // If user exists, set the username and mark them as logged in
          setUsername(userEntry.username);
          setHasAccount(true);
          setLoggedIn(true);
          setMessage('Welcome back!');
          
        } else {
          // If no user found, prompt to create a new account
          setHasAccount(false);
          setLoggedIn(false);  // Ensure that loggedIn is false at this stage
          setMessage('No previous activity detected. Please create an account.');
        
        }
        setWalletConnected(true); // Set walletConnected to true after connecting
      } catch (error) {
        console.error('Error connecting to Lace wallet:', error);
        setMessage('Error connecting to Lace wallet. Please try again.');
      }
    } else {
      setMessage('Lace wallet not found. Please install the Lace wallet extension.');
    
      
    }
  };
  
  
 
  
  
  
  const disconnectLaceWallet = () => {
    resetGameStates(); // Reset any other game-related states
    setLoggedIn(false);
    setWalletConnected(false);
    setSelectedTopic(null);
    setGameStarted(false);
    setHasAccount(false);
    setLoggedIn(false);
    setWalletAddress('');
    setUsername('');
    setMessage('Lace wallet disconnected. Please reconnect to play.');
    
  };




  
  const filter = new Filter();

// Add custom words or alternate spellings (optional)
filter.addWords('h3ll', 'f@ck', '5hit', 'b@dword');

// Helper function to check for inappropriate language
const containsBadLanguage = (username) => {
  return filter.isProfane(username);
};

// Helper function to check if the username already exists in the database
const doesUsernameExist = async (username) => {
  const { data } = await supabase
    .from('users')
    .select('username')
    .eq('username', username)
    .single();

  return data ? true : false; // Return true if the username exists, false otherwise
};

// Main function to handle username submission
const handleUsernameSubmit = async (e) => {
  e.preventDefault();

  // Check if the username exceeds 15 characters
  if (username.length > 15) {
    setMessage('Username must not exceed 15 characters');
    return;
  }

  // Check for inappropriate language
  if (containsBadLanguage(username)) {
    setMessage('Username contains inappropriate language');
    return;
  }

  // Check if the username already exists
  const usernameExists = await doesUsernameExist(username);
  if (usernameExists) {
    setMessage('This username is already taken');
    return;
  }

  // Save the username and wallet address to the database
  const { success, error } = await saveUserToDatabase(walletAddress, username);

  if (success) {
    setHasAccount(true);
    setLoggedIn(true);
    setMessage(`Welcome, ${username}!`);
  } else {
    setMessage(`Error: ${error.message}`);
  }
};
  



 // Function to update XP in the state
 const updateXP = async () => {
  if (walletAddress) {
    const xp = await fetchUserXP(walletAddress);  // Call the fetchUserXP function
    if (xp !== null) {
      setTotalXP(xp);  // Update the state with fetched XP
    }
  }
};

// Fetch XP when the wallet address is available
useEffect(() => {
  if (walletAddress) {
    updateXP();
  }
}, [walletAddress]); // Add updateXP to the dependency array
  
 
// Call this function after the game ends to save XP based on the score
const handleGameEnd = async (score, walletAddress) => {
  // Save XP in the database
  await saveXPToDatabase(walletAddress, score);

  refreshLeaderboard();  // Refresh the leaderboard after the game ends

  // After saving XP, fetch the updated XP to update the UI
  updateXP();  // Call the function that fetches and updates the XP
};

// Fetch users when component mounts
useEffect(() => {
  const fetchData = async () => {
    const topUsers = await fetchTopUsers();  // Call the Supabase function
    if (topUsers) {
      setUsers(topUsers);  // Update the state with fetched users
    }
  };

  fetchData();  // Trigger the fetch
}, []);  // Empty dependency array to only fetch on mount


 // Fetch users when component mounts
 const refreshLeaderboard = async () => {
  const topUsers = await fetchTopUsers();  // Call the Supabase function
  if (topUsers) {
    // Ensure there are 25 spots, fill with placeholders if necessary
    const filledUsers = [...topUsers, ...Array(25 - topUsers.length).fill({ username: '', total_xp: 0 })];
    setUsers(filledUsers);
  }
};

useEffect(() => {
  refreshLeaderboard();  // Fetch leaderboard on component mount
}, []);  // Empty dependency array to only fetch on mount
  

  const topics = {
    Crypto: {
      name: 'Crypto',
      categories: ['Bitcoin and Crypto Basics', 'Smart Contracts', 'Crypto Safety', 'NFTs, Coins and Dapps', 'Cardano'],
    },
    ComingSoon: {
      name: 'Coming Soon',
      categories: ['Coming Soon'],
    },
   
    TBA: {
      name: 'TBA',
      categories: ['Coming Soon'],
    },
    Whatshoulditbe: {
      name: 'What should it be?',
      categories: ['Coming Soon'],
    },
    thinking: {
      name: ' 🤔',
      categories: ['Coming Soon'],
    },
  };
  
  const fetchQuestions = async (topic, category, level) => {
    const tableName = `${topic}_${category.replace(/\s+/g, '')}_Level_${level}`;
    
    const { data, error } = await supabase.from(tableName).select('*');
  
    if (error) {
      console.error(`Error fetching questions from ${tableName}:`, error);
      return;
    }
  
    const shuffledQuestions = shuffleArray(data);
    setQuestions(shuffledQuestions);
    setCurrentQuestion(0);
    setScore(0);
    setIncorrectScore(0);
    setGameStarted(true);
    setTimer(30); // Set timer to 15 seconds
    setShowConfetti(false);
    setShowFinalScore(false);
    shuffleCurrentAnswers(shuffledQuestions[0]);
  };
  
  const shuffleArray = (array) => {
    return array.sort(() => Math.random() - 0.5);
  };
  
  const shuffleCurrentAnswers = (question) => {
    if (question) {
      const answers = [
        question?.incorrect_answer1,
        question?.incorrect_answer2,
        question?.incorrect_answer3,
        question?.correct_answer,
      ];
      setShuffledAnswers(shuffleArray(answers));
    }
  };
  
  const handleAnswerSelection = (answer, isTimeout = false) => {
    const current = questions[currentQuestion];
    if (!current || selectedAnswer) return;
  
    setSelectedAnswer(answer);
    setTimerRunning(false);
  
    setTimeout(() => {
      if (isTimeout || answer !== current.correct_answer) {
        setIncorrectScore(incorrectScore + 1);
        setFeedback('Incorrect');
        setFeedbackType('incorrect');
      } else {
        setScore(score + 1);
        setFeedback('Correct!');
        setFeedbackType('correct');
      }
  
      setExplanationText(current.explanation || 'No explanation available.');
      setShowPopup(true);
    }, 100);
  };
  
  const handleNextQuestion = () => {
    setExplanationText('');  // Clear explanation
    setShowPopup(false);
    setFeedback('');
    setFeedbackType('');
    setSelectedAnswer(null);
    setTimerRunning(true);
  
    if (currentQuestion + 1 < questions.length) {
      const nextQuestion = questions[currentQuestion + 1];
      setCurrentQuestion(currentQuestion + 1);
      setTimer(30); // Reset timer to 15 seconds
      shuffleCurrentAnswers(nextQuestion);
    } else {
      setShowConfetti(true);
      setShowFinalScore(true);
      setTimeout(() => {
        setShowConfetti(false);
        setGameStarted(false);
        setShowFinalScore(false);
      }, 10000);
    }
  };
  
  // Timer management with useEffect
  useEffect(() => {
    let timerId;
    if (timer > 0 && gameStarted && timerRunning) {
      timerId = setTimeout(() => {
        setTimer(timer - 1);
      }, 1000);
    } else if (timer === 0 && !selectedAnswer) {
      handleAnswerSelection(null, true); // Timeout if no answer selected
    }
  
    return () => clearTimeout(timerId);
  }, [timer, gameStarted, timerRunning, selectedAnswer]);
  
  
  

  
  const startGame = () => {
    if (selectedTopic && selectedCategory && selectedLevel) {
      fetchQuestions(selectedTopic, selectedCategory, selectedLevel);
      setTimer(30); // Reset timer on game start
      setTimerRunning(true); // Start the timer
    }
  };
  
  const renderConfetti = () => {
    return showConfetti && (
      <Confetti width={window.innerWidth} height={window.innerHeight} />
    );
  };

  // Define the resetGameStates function
const resetGameStates = () => {
  // Reset game-related states
  setGameStarted(false);
  setSelectedCategory(null);
  setSelectedLevel(null);
  setShowQuitPopup(false); // Hide the quit popup
  setScore(0);
  setIncorrectScore(0);
  setSelectedAnswer(null); // Reset selected answer
  setFeedback('');         // Reset feedback
  setFeedbackType('');     // Reset feedback type
  setCurrentQuestion(0);   // Reset to the first question
  setShuffledAnswers([]);  // Reset shuffled answers
  setExplanationText('');  // Reset explanation text
  setTimer(30);            // Reset the timer to the initial value (you can adjust this)
  setTimerRunning(false);  // Ensure timer is not running immediately
};



  
const handleLevelSelection = async (category, level) => {
  const categoryProgress = await fetchCategoryProgress(walletAddress, category); // Fetch category progress
  const unlocked = isLevelUnlocked(categoryProgress, level);

  if (unlocked) {
    setSelectedCategory(category);
    setSelectedLevel(level);
  } else {
    alert('This level is locked. Complete the previous level to unlock it.');
  }
};

// Level is unlocked if highest completed level is one less than the current level
const isLevelUnlocked = (categoryProgress, level) => {
  if (!categoryProgress || !categoryProgress.highest_level_completed) {
    return level === 1; // Ensure level 1 is always unlocked
  }
  return categoryProgress.highest_level_completed >= level - 1;
};

// Level is completed if the highest completed level is greater than or equal to the current level
const isLevelCompleted = (categoryProgress, level) => {
  if (!categoryProgress || !categoryProgress.highest_level_completed) {
    return false; // Default to not completed if no progress exists
  }
  return categoryProgress.highest_level_completed >= level;
};


// Fetch all button classes for a category at once
const fetchButtonClasses = async (category) => {
  try {
    const categoryProgress = await fetchCategoryProgress(walletAddress, category);

   

    // Initialize the updatedButtonClasses object
    const updatedButtonClasses = {};

    [1, 2, 3, 4, 5].forEach((level) => {
      if (isLevelCompleted(categoryProgress, level)) {
        updatedButtonClasses[level] = 'completed';
      } else if (isLevelUnlocked(categoryProgress, level)) {
        updatedButtonClasses[level] = 'unlocked';
      } else {
        updatedButtonClasses[level] = 'locked';
      }
    });

    

    setButtonClasses((prevClasses) => ({
      ...prevClasses,
      [category]: updatedButtonClasses,
    }));
  } catch (error) {
    console.error("Error fetching button classes:", error);
  }
};

// Call this function in useEffect or where necessary
useEffect(() => {
  if (selectedCategory) {
    fetchButtonClasses(selectedCategory);
  }
}, [selectedCategory]);






// Call this function in useEffect when selectedTopic changes
useEffect(() => {
  if (selectedTopic) {
    topics[selectedTopic].categories.forEach((category) => {
      fetchButtonClasses(category); // Fetch button classes for each category in the topic
    });
  }
}, [selectedTopic]);


const handleLevelCompletion = async (score, totalQuestions, walletAddress, category, level) => {
  if (score === totalQuestions) {
    // If the user answered all questions correctly
   

    // Mark the level as completed in the database
    const result = await completeLevel(walletAddress, category, level);

    if (result.success) {
     
      // Optionally, fetch progress again to reflect the newly unlocked level
      fetchButtonClasses(category);
    } else {
      console.error("Error marking level as completed:", result.error);
    }
  } else {
    //User did not complete the level perfectly, next level remains locked
  }
};




  // Setup the emoji reward hook
  const { reward: triggerEmojiBurst, ref: rewardRef } = useReward('emoji', 'emoji', {
    elementCount: 50, // Number of emojis
    lifetime: 200, // Duration of emoji animation in milliseconds
  });


// Function to show the quit game popup and hide other elements
const handleQuitGame = () => {
  setShowQuitPopup(true); // Show the quit popup
};

// Function to acknowledge quitting and reset the game
const handleConfirmQuit = () => {
  setShowQuitPopup(false); // Hide the quit popup
  setGameStarted(false);   // Reset the game state
  setSelectedCategory(null);
  setSelectedLevel(null);
  setScore(0);             // Reset score
  setIncorrectScore(0);    // Reset incorrect score
  resetGameStates();       // Reset all other game states
};

// Function to cancel quitting and continue the game
const handleCancelQuit = () => {
  setShowQuitPopup(false); // Just hide the popup, game continues
};








// **************** Things to add***************



// Will add streak system
//more advanced rewards
//multipler 
//suggestion box
//voting??


//improve questions
// advertising

  










return (
  
<div className="App">


  
<div className="title-container">
  <div className="side-left">
    <img
      src="cardano_logo.png"
      alt="Cardano logo"
      className="logo"
    />
  </div>
  <h1 className="game-title">CryptoTrivia.fun</h1>
  <div className="crypto-icons">
    <span role="img" aria-label="rocket">🚀</span>
    <span role="img" aria-label="moon">🌕</span>
    <span role="img" aria-label="diamond">💎</span>
    <span role="img" aria-label="coin">🪙</span>
    <span role="img" aria-label="fire">🔥</span>
  </div>
  <div className="side-right">
    <img
      src="bitcoin-btc-logo.png"
      alt="Bitcoin logo"
      className="logo"
    />
  </div>
</div>



<div className="description-container">
  <img src="trivia.png" alt="Trivia Icon" className="trivia-icon left-icon" />
  <p className="game-description">
    Welcome to CryptoTrivia.fun – Dive into Crypto the Fun Way!<br /><br />
    Whether you're a beginner or you're an experienced developer, CryptoTrivia.fun is the perfect place to test your knowledge. <br /><br />
    The questions will explore the fascinating universe of cryptocurrency. From basic blockchain concepts to complex smart contract coding, our trivia challenges are designed to be both fun and interesting, with difficulty levels ranging from beginner to pro.<br /><br />
   
    Currently in Beta, Thanks for Testing!
  </p>
  <img src="trivia.png" alt="Trivia Icon" className="trivia-icon right-icon" />
</div>









  {/* Main Screen */}
  <div className="main-screen">
   

  {!walletConnected ? (
  // Stage 1: Show connect button when wallet is not connected
  <div className="network-buttons-container">
    <button className="connect-wallet-button" onClick={connectLaceWallet}>
      <img src="/cardano_logo.png" alt="Cardano" className="network-logo cardano-logo" />
      Connect to Cardano with Lace
    </button>

    {/* Section for Lace wallet instructions */}
    <div className="lace-wallet-instructions">
  
      <a href="https://www.lace.io" target="_blank" rel="noopener noreferrer" className="lace-link">
      
        Don't have a Lace wallet? Get Lace Wallet Here  <img src="/lace_wallet_logo.png" alt="Lace Wallet" className="lace-logo" />
      </a>
    </div>
  </div>
    ) : !hasAccount ? (
      // Stage 2: Prompt to create an account if no previous activity is found
      <div className="wallet-container">
        <div className="username-container">
          <h3>No previous activity detected</h3>
          <p>Enter a username to track progress in a human-readable way!</p>
          <form onSubmit={handleUsernameSubmit}>
            <label htmlFor="username">Enter Username:</label>
            <input
              type="text"
              id="username"
              className="username-input"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
              maxLength={15} // Restricts input to 15 characters
            />
            <button type="submit" className="create-account-button">
              Create Account
            </button>
          </form>
        </div>
      </div>
    ) : loggedIn ? (
      // Stage 3: Show welcome message and additional content when logged in
      <div className="wallet-container">
        <p className="wallet-address">
          Successfully Connected and Signed In! <br />
          <br /> User ID Signed In: {username}
        </p>
        <button className="disconnect-wallet-button" onClick={disconnectLaceWallet}>
          Disconnect Wallet and Sign Out
        </button>
        {/* Additional content that only appears once logged in */}
        <div className="additional-content">{/* Your additional content goes here */}</div>
      </div>
    ) : null}

    {/* Message Box */}
    <div className="message-box">
      <p>{message}</p>
    </div>
  </div>

  {/* Game Container */}
  {!gameStarted && loggedIn && (
    <div className="game-container">
      <DailyQuestion walletAddress={walletAddress} updateXP={updateXP} />

      {/* XP Display Container */}
      <div className="xp-container">
        <h3 className="xp-title">Your Total XP</h3>
        <p className="xp-value">{totalXP}</p>
      </div>

      {/* Instructions Section */}
      <div className="instructions-container">
        <p>
          <strong>Level Color Code:</strong>
        </p>
        <ul>
          <li>
            <span className="locked-color"></span> Locked 
          </li>
          <li>
            <span className="completed-color"></span> Completed 
          </li>
          <li>
            <span className="unlocked-color"></span> Unlocked 
          </li>
          <li>
            <span className="selected-color"></span> Selected 
          </li>
        </ul>
      </div>

      {/* Topics Section */}
      <h2>Select a Topic (More Coming Soon!)</h2>
      <div className="topics-container">
        {Object.keys(topics).map((topicKey) => (
          <button
            key={topicKey}
            className={`topic-btn ${selectedTopic === topicKey ? 'selected' : ''}`}
            onClick={() => {
              if (topicKey === 'Crypto') {
                // Only allow 'Crypto' to be selected
                setSelectedTopic(topicKey);
              }
            }}
            disabled={topicKey !== 'Crypto'} // Disable buttons that are not 'Crypto'
          >
            {topics[topicKey].name}
          </button>
        ))}
      </div>

      {/* Category Selection */}
      {selectedTopic && (
        <div className="category-container">
          <h2>Select a Category and Level</h2><br/>
          <div className="categories-container">
            {topics[selectedTopic].categories.map((category) => (
              <div key={category} className="category-group">
                <h3 className="category-name">{category}</h3>

                <div className="levels">
                  {[1, 2, 3, 4, 5].map((level) => {
                    // Define isSelected for the current level
                    const isSelected = selectedCategory === category && selectedLevel === level;

                    // Define the button class based on completion, unlocking, or selection
                    const buttonClass = isSelected ? 'selected' : buttonClasses[category]?.[level] || 'locked';

                    return (
                      <button
                        key={`${category}-level-${level}`}
                        className={`level-btn ${buttonClass}`} // Apply the calculated button class
                        onClick={() => handleLevelSelection(category, level)}
                        disabled={buttonClass === 'locked'} // Disable locked levels
                      >
                        Level {level}
                      </button>
                    );
                  })}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Start Game Button */}
      {selectedCategory && selectedLevel && (
        <button className="start-game-btn" onClick={startGame}>
          Start Game
        </button>
      )}
    </div>
  )}

  {/* Quit Game Popup */}
{/* Quit Game Popup */}
{showQuitPopup && (
  <div className="quit-popup-container">
    <p className="quit-message">Are you sure you want to quit? Quitters get no XP!</p>
    <div className="popup-buttons">
      <button className="cancel-button" onClick={handleCancelQuit}>Don't Quit</button>
      <button className="confirm-quit-button" onClick={handleConfirmQuit}>Quit</button>
    </div>
  </div>
  )}

 {/* Game Container (when the game is started) */}
{gameStarted && !showFinalScore && ( // Hide when the final score is showing
  <div className="game-container">
    {/* Quit Game Button */}
    <button className="quit-game-btn" onClick={handleQuitGame}>
      Quit Game Instance
    </button>

    {/* Score Box */}
    <div className="score-box">
      <p>Correct: {score}</p>
      <p>Incorrect: {incorrectScore}</p>
      <p>Question {currentQuestion + 1}/{questions.length}</p>
    </div>

    {!showFinalScore && (
      <div className={`question-container`}>
        {questions.length > 0 && currentQuestion < questions.length ? (
          <>
            <h2 className="question">{questions[currentQuestion]?.question}</h2>
            <div className="answers">
              {shuffledAnswers.map((answer, idx) => (
                <button
                  key={idx}
                  className={`answer-btn ${
                    selectedAnswer === answer
                      ? answer === questions[currentQuestion].correct_answer
                        ? 'correct'
                        : 'incorrect'
                      : ''
                  }`}
                  onClick={() => handleAnswerSelection(answer)}
                  disabled={!!selectedAnswer}
                >
                  {answer}
                </button>
              ))}
            </div>
            <p className={`feedback ${feedbackType}`}>
              {feedback || 'Waiting for answer...'}
            </p>
            <p className="timer">
              {showPopup ? 'Question Completed' : `Time left: ${timer}s`}
            </p>
          </>
        ) : null}
      </div>
    )}

    {/* Explanation Popup */}
    {!showFinalScore && (
      <div className="popup-container">
        <p className="popup-text">{selectedAnswer ? explanationText : 'Waiting for answer...'}</p>
        <button
          className="popup-button"
          onClick={() => {
            if (currentQuestion + 1 === questions.length) {
              setShowPopup(false);
              setShowConfetti(true);
              setShowFinalScore(true);

              // Confetti and final score logic
              setTimeout(() => {
                setShowConfetti(false);
              }, 10000); // Confetti for 10 seconds
            } else {
              handleNextQuestion(); // Move to the next question
            }
          }}
          disabled={!selectedAnswer}
        >
          {currentQuestion + 1 === questions.length
            ? selectedAnswer ? 'Finish Question Set' : 'Waiting for answer...'
            : selectedAnswer ? 'Next Question' : 'Waiting for answer...'}
        </button>
      </div>
    )}
  </div>
)}



{/* Confetti */}
{showConfetti && renderConfetti()}

{/* Final Score Popup */}
 {/* Final Score Popup */}
 {showFinalScore && (
        <div className="final-score-container">
          <h2 className="final-score-message">🎉 Congratulations! 🎉</h2>
          <p className="final-score">You scored {score}/{questions.length}</p>
          <p className="final-score">Click Acknowledge Score To Receive XP!</p>

          {/* Acknowledge button with reward and delay */}
          <div>
            <button
              className="acknowledge-button"
              onClick={() => {
                triggerEmojiBurst(); // Trigger emoji burst
                setTimeout(() => {
                  // Delay the reset actions to allow the emoji burst to finish
                  saveXPToDatabase(walletAddress, score); // Call to backend to save XP
                  handleGameEnd(score, walletAddress); // Pass the score and walletAddress to handleGameEnd
                  handleLevelCompletion(score, questions.length, walletAddress, selectedCategory, selectedLevel); // check if next level unlocked
                  setShowConfetti(false);
                  setShowFinalScore(false);
                  setGameStarted(false);
                  setSelectedCategory(null);
                  setSelectedLevel(null);
                  setScore(0);
                  setIncorrectScore(0);
                  resetGameStates(); // Reset all game states after the game ends
                }, ); 
              }}
            >
              Acknowledge Score
            </button>
            
       
          </div>
        </div>
      )}

      






    {/* Leaderboard Section */}
    <div className="leaderboard-container">
      <h2 className="leaderboard-title">Top 25 Players</h2>
      <button className="refresh-button" onClick={refreshLeaderboard}>
        Refresh Leaderboard
      </button>
      <div className="leaderboard">
        {users.map((user, index) => (
          <div key={index} className="leaderboard-row">
            <span className="rank">{index + 1}</span>
            <span className="username">{user.username || 'Spot Empty - Need more players'}</span>
            <span className="xp">{user.total_xp !== undefined ? `${user.total_xp} XP` : '0 XP'}</span>
          </div>
        ))}
      </div>
    </div>



    {/* About Section */}
    <div className="about-section">
      <h2>About the Game</h2>
      <p>
        A few friends of mine mentioned that they wanted to dive deeper into crypto,
        but they felt overwhelmed by all the information out there. So, I figured, why not make it fun?
        That’s how this trivia game came to life—a simple and enjoyable way to explore the main topics in crypto.
      </p>
      <br/>
      <p>
        The game has five levels, with Level 1 being a good starting point for beginners, while Level 5 is a real challenge,
        even for those who are more experienced. You get 1 XP for every question you answer correctly,
        but there's a catch: you only earn XP if you finish the entire game instance.
        To make it even more interesting, you need to answer all the questions correctly in a level before you can move on to the next one in that category.
      </p>
      <br/>
      <p>
        With the Cardano Chang Hardfork and governance coming online, I thought it was a good time to add a fun way for people to dive deeper into crypto,
        especially for those who aren’t as familiar with the space yet. Whether you're just starting out or already have some experience,
        this trivia game is here to make exploring crypto both enjoyable and engaging.
      </p>
      <br/>
      <p>
        I’m doing this project for fun, and I’ll keep adding more as I get feedback from others using it.
        Feel free to share your thoughts on how to improve it!
      </p>
      <br/>
      <p>
        This trivia game is meant for entertainment purposes only. It’s just a fun way to explore crypto topics!
      </p>
    </div>

    {/* Team Section */}
    <div className="team-section">
      <h2>Meet the Team</h2>
      <div className="profile">
        <div className="profile-image-section">
          <img src="myseal.jpg" alt="Profile" className="profile-picture" />
          {/* Twitter Handle under the image */}
          <div className="social-media">
            <a href="https://twitter.com/Cold_Liquid_" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-twitter"></i> @Cold_Liquid_
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
);
};




export default App;
